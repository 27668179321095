body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.heading-wrapper-center.inner-padding-medium.our-roadmap {
  padding-top: 10px;
  background: linear-gradient(0.25turn, #65CC1C -100.00%, #010101 51.00%);
  /*background-color: #161616;*/
 
text-align: center;
padding-bottom: 60px;
}
.heading-wrapper-center.inner-padding-medium.our-roadmap h1{
  font-size: 44px;
  font-family: "Ethnocentric";
  color:#fff;
}
@media (max-width: 375px) {
  .heading-wrapper-center.inner-padding-medium.our-roadmap h1 {
    font-size: 36px;
  }
}

.section-timeline {
  position: relative;
  z-index: -3;
  background: linear-gradient(0.25turn, #65CC1C -100.00%, #010101 51.00%)
}
.section-timeline img{
  width: 100%;
}
.container-2 {
  width: 90vw;
  max-width: 1360px;
  margin-right: auto;
  margin-left: auto;
}

.timeline_wrapper {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  max-width: 1120px;
  margin-right: auto;
  margin-left: auto;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}


.timeline_progress-bar.mobile-bar {
  background-color: transparent;
  background-image: linear-gradient(180deg, #23da79, #76F016);
}
.timeline_progress-bar {
  position: fixed;
  left: auto;
  top: 0px;
  right: auto;
  bottom: 50vh;
  z-index: -1;
  width: 3px;
  height: 50vh;
  background-clip: border-box;
  -webkit-text-fill-color: inherit;
}
.timeline_progress {
  position: absolute;
  z-index: -2;
  width: 3px;
  height: 100%;
  background-color: #2f2f2f;
}

.timeline_item {
  position: relative;
  z-index: 2;
  display: -ms-grid;
  display: grid;
  padding-top: 80px;
  padding-bottom: 80px;
  grid-auto-columns: 1fr;
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  -ms-grid-columns: 1fr 180px 1fr;
  grid-template-columns: 1fr 180px 1fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
  color: rgb(255, 255, 255);
}
.timeline_left {
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -webkit-box-align: stretch;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  text-align: right;
}
.timeline_right {
  font-size: 22px;
}
.timeline_date-text {
  position: -webkit-sticky;
  position: sticky;
  top: 50vh;
  font-size: 30px;
  line-height: 1.2;
  font-weight: 500;
  letter-spacing: -0.03em;
}


strong div{
  font-size: 50px;
  color: #76F016;
}

#precio {
  font-size: 20px;
  color: #76F016;
}


.timeline_centre {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
}
.timeline_circle {
  position: -webkit-sticky;
  position: sticky;
  top: 50vh;
  overflow: visible;
  width: 15px;
  height: 15px;
  max-height: 15px;
  max-width: 15px;
  min-height: 15px;
  min-width: 15px;
  border-radius: 100%;
  background-color: #fff;
  box-shadow: 0 0 0 8px #2f2f2f;
  opacity: 1;
}
.overlay-fade-bottom {
  position: absolute;
  width: 20px;
  background-color: #010101;
  left: 34rem;
  top: auto;
  right: 0%;
  bottom: 0%;
  height: 80px;
}
.overlay-fade-top {
  position: absolute;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: auto;
  height: 80px;
}

@media (max-width:565px) {
  .timeline_date-text{
    font-size: 26px;
  }

  .timeline_right{
    font-size: 18px;
  }

  .overlay-fade-bottom{
    display: none;
  }
}
@media (max-width: 715px) {
  .container-2{
    margin:0;
  }
  .timeline_progress-bar{
    left:9%;
  }
  .timeline_progress{
    left:10%;
  }
  .timeline_item {
    -ms-grid-columns: 0.5fr 0px 1.5fr;
    grid-template-columns: 0.5fr 0px 1.5fr;
  }
  .timeline_circle {
    left: 7.5%
  }
  .timeline_date-text{
    position: inherit;
  }
}

.colorRoadmap {
  color:#fff;
}

.TeamImagen {
width: 200px;
height: 200px;
border-radius: 100%;
}

a {
  color: white;
  text-decoration: none;
}

.showcase {
  position: relative;
}

.subHeader {
  position: absolute;
}


