
.body-loading {
	overflow: hidden;
	overflow-y: hidden;
	overflow-x: hidden;
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #001122;
    position: fixed;
    z-index: 3;
}

.svg-frame-loading {
	position: relative;
	width: 455px;
	height: 455px;
	transform-style: preserve-3d;
	display: flex;
	justify-content: center;
	align-items: center;
	animation: change-view 2s ease-in infinite;
}

@keyframes change-view {
	0%,
	50% {
		transform: rotate(-0deg) skew(00deg) translateX(calc(0 * var(--i)))
			translateY(calc(-0px * var(--i)));
	}

	70%,
	100% {
		transform: rotate(-80deg) skew(30deg) translateX(calc(45px * var(--i)))
			translateY(calc(-35px * var(--i)));
	}
}

svg {
	position: absolute;
	transition: 0.5s;
	transform-origin: center;
	width: 450px;
	height: 450px;
	fill: none;
	animation: change-view 5s ease-in-out infinite alternate;
	filter: drop-shadow(0 0 12px #73EC43);
}

#big-centro,
#outter1,
#solo-lines,
#center,
#outter-center,
#bottom-dots,
#center-lines,
#squares,
#top-dots {
	transform-origin: center;
	animation: rotate 4s ease-in-out infinite alternate;
}

#big-centro {
	animation-delay: -1.5s;
}

#outter1 {
	animation-delay: -1.2s;
}

#center {
	animation-delay: -2.2s;
}

#bottom-dots,
#top-dots {
	animation-duration: 7s;
}

#center-lines,
#outter-center {
	animation-duration: 6s;
	animation-delay: -3s;
}

@keyframes rotate {
	to {
		transform: rotate(360deg);
	}
}


.svg-loading-base {
	--i:0;
}

.svg-loading-big-centro {
	--i:1;
}

.svg-loading-solo-lines {
	--i:2;
}

.svg-loading-outter-center {
	--i:3;
}

.svg-loading-center-lines {
	--i:4;
}

.svg-loading-center {
	--i:5;
}

